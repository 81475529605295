<template>
  <div class="card">
    <img v-if="isImage" :src="upload.standard" class="card-img-top" />
    <h3 class="card-header" v-else>
      <font-awesome-icon icon="file-pdf"></font-awesome-icon>
    </h3>
    <div class="card-body">
      <strong class="card-title"><span v-html="name"></span></strong>
      <p v-if="upload.description !== ''">
        <span v-html="upload.description"></span>
      </p>
    </div>
    <div class="card-footer" v-if="!isImage">
      <cs-pdf-button :url="upload.path" type="print"></cs-pdf-button>
      <cs-pdf-button :url="upload.path" type="download"></cs-pdf-button>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash/core'
export default {
  name: 'Upload',
  props: {
    upload: {
      type: Object,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  computed: {
    name () {
      return this.upload.name !== '' ? this.upload.name : this.upload.filename
    },
    isImage () {
      return !isEmpty(this.upload.type) && this.upload.type.includes('image')
    },
    pathToDownload () {
      return this.upload.path
    }
  }
}
</script>

<style scoped></style>
