<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="card w-100" :class="'border-' + slug">
      <div class="card-header" :class="'bg-' + slug + '-light'">
        <h3>
          <strong>
            <span v-html="program.name"></span>
          </strong>
          <span class="float-right">
            <cs-disclose v-model="details"></cs-disclose>
          </span>
        </h3>
      </div>
      <transition>
        <div v-if="details" class="list-group list-group-flush">
          <div class="list-group-item">
            <div class="row">
              <div class="col">
                <strong>Registration Fee </strong
                >{{ currency(fee)}}
                <template
                  v-if="parseInt(program.additional_registration_fee) > 0"
                  >/{{
                    currency(additionalFee)
                  }} </template
                ><br />
                <strong
                  v-if="program.payment_options_list !== 'No Special Options'"
                  >Payment Options: </strong
                >{{
                  program.payment_options_list === "No Special Options"
                    ? ""
                    : program.payment_options_list
                }}
              </div>
              <div class="col">
                <template
                  v-if="currentRegistrationOpen || nextYearRegistrationOpen"
                >
                  <strong
                    >{{ schoolYear(program.current_year) }} Registration:
                  </strong>
                  {{ dateTimeRange(program.registration_start, program.registration_end)
                  }}<br />
                  <strong
                    >{{ schoolYear(program.current_year + 1 )}}
                    Registration:
                  </strong>
                  {{
                    dateTimeRange(program.next_year_registration_start,program.next_year_registration_end)
                  }}<br />
                </template>
                <template v-else>
                  <strong
                    >No Registration period currently or pending for
                    <span v-html="program.name" /></strong
                  >
                </template>

                <p v-if="program.require_enroll" class="text-muted small">
                  This Program expects that students are enrolled and paid in at
                  least one class in order for the registration to be completed.
                </p>
              </div>
              <div class="col">
                <a
                  :href="'mailto:' + program.admin_email"
                  class="btn btn-primary"
                >
                  <cs-icon icon="email"></cs-icon>
                  <span class="pl-1" v-html="program.admin_name"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="list-group-item" v-if="showSitesAndGrades">
            <div class="row">
              <div class="col"><strong>Sites: </strong>{{ program.sites }}</div>
              <div class="col">
                <strong>Grades: </strong>{{ toList(program.grades) }}
              </div>
            </div>
          </div>
          <div class="list-group-item">
            <div class="row">
              <div class="col"
                   v-if="onlyDescription"
              >
                <span v-html="description" />
              </div>
              <div class="col"
                   v-else>
                <span class="float-right">
                  <cs-disclose v-model="showDetail">
                    <template v-slot:undisclosed>Brief </template>
                    <template v-slot:disclosed>More </template>
                  </cs-disclose>
                </span>
                <transition name="fade">
                  <span v-if="!showDetail" v-html="program.brief"></span>
                  <span v-else v-html="program.detail"></span>
                </transition>
              </div>
            </div>
          </div>
          <div
            class="list-group-item"
            v-if="showUploads && hasPublicUploads"
          >
            <div class="row">
              <div
                class="col col-lg-3 col-md-4 col-sm-6"
                v-for="upload in publicUploads"
                :key="upload.id"
              >
                <app-upload :upload="upload" :compact="compact"></app-upload>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import Upload from '@/components/shared/Upload'
import parseISO from 'date-fns/parseISO'
import addYears from 'date-fns/addYears'
import { dateTimeString, timeString, dateString, dateTimeRange, schoolYear } from '@/utilities/dateFormatters'
import { toList, currency } from '@/utilities/stringFormatters'
import { isEmpty } from 'lodash/core'
import CsDisclose from '@/components/controls/CsDisclose.vue'
import { isDateBetween } from '@/utilities/utilities'

export default {
  name: 'ProgramSummary',
  props: {
    program: {
      type: Object,
      required: true
    },
    showUploads: {
      type: Boolean,
      default: true
    },
    compact: {
      type: Boolean,
      default: false
    },
    year: {
      type: [String, Number],
      required: false,
      default: new Date().getFullYear()
    }
  },
  data () {
    return {
      details: true,
      showDetail: false,
      showSitesAndGrades: false
    }
  },
  components: {
    CsDisclose,
    appUpload: Upload
  },
  watch: {
    // whenever question changes, this function will run
    details: function (newDetail) {
      localStorage.setItem(this.programDetailsKey, newDetail)
    }
  },
  created () {
    if (localStorage.getItem(this.programDetailsKey)) {
      const detail = localStorage.getItem(this.programDetailsKey)
      if (detail === 'false') {
        this.details = false
      } else {
        this.details = true
      }
    }
  },
  computed: {
    fee () {
      const registrationFee = this.registrationFee
      if (!isEmpty(registrationFee)) {
        return registrationFee.fee
      } else {
        return this.program.registration_fee
      }
    },
    additionalFee () {
      const registrationFee = this.registrationFee
      if (!isEmpty(registrationFee)) {
        return registrationFee.second_fee
      } else {
        return this.program.additional_registration_fee
      }
    },
    registrationFee () {
      if (!isEmpty(this.program.registration_fees)) {
        const registrationFee = this.program.registration_fees.find(fee => {
          return parseInt(this.year) === fee.year
        })
        if (!isEmpty(registrationFee)) {
          return registrationFee
        }
      }
      return null
    },
    hasPublicUploads () {
      return this.publicUploads.length > 0
    },
    publicUploads () {
      if (isEmpty(this.program.uploads) || this.program.uploads.length === 0) {
        return []
      }
      return this.program.uploads.filter(upload => {
        return upload.active
      })
    },
    programDetailsKey () {
      return this.program.slug + '.details'
    },
    isEmpty () {
      return isEmpty
    },
    slug () {
      return this.program.slug
    },
    currentRegistrationOpen () {
      return isDateBetween(this.program.registration_start,
        this.program.registration_end,
        new Date())
    },
    nextYearRegistrationOpen () {
      return isDateBetween(this.program.next_year_registration_start, this.program.next_year_registration_end, new Date())
    },
    nextYearStart () {
      const start = parseISO(this.program.start)
      return addYears(start, 1)
    },
    nextYearEnd () {
      const end = parseISO(this.program.end)
      return addYears(end, 1)
    },
    hasDetail () {
      return this.program.detail.trim() !== ''
    },
    brief () {
      return this.program.brief.trim()
    },
    detail () {
      return this.program.detail.trim()
    },
    onlyDescription () {
      if (this.brief !== this.detail) {
        return this.description !== ''
      }
      return this.brief === this.detail
    },
    description () {
      if (this.hasDetail) {
        return this.program.detail
      }
      if (this.hasBrief) {
        return this.program.brief
      }
      return ''
    }
  },
  methods: {
    dateTimeString,
    dateTimeRange,
    dateString,
    timeString,
    schoolYear,
    toList,
    currency
  }
}
</script>

<style scoped></style>
